// Wait for full page load
window.addEventListener('load', function() {
    try {
        console.log('Working group script loaded');
        
        // Find our elements - use querySelectorAll instead of querySelector
        const signupLinks = document.querySelectorAll('.working-group-signup');
        const removeLinks = document.querySelectorAll('.working-group-remove');
        
        // Guard clause - if no links exist, don't proceed
        if (signupLinks.length === 0 && removeLinks.length === 0) {
            console.log('No working group links found');
            return;
        }
        
        async function sendWorkingGroupWebhook(action, userData, themeTitle) {
            console.log('Sending webhook:', { action, userData, themeTitle });
            const webhookUrl = 'https://hooks.zapier.com/hooks/catch/5114703/2wb3xhi/';
            
            try {
                const response = await fetch(webhookUrl, {
                    method: 'POST',
                    body: JSON.stringify({
                        user: {
                            firstName: userData.firstName,
                            lastName: userData.lastName,
                            email: userData.email
                        },
                        theme: themeTitle,
                        action: action
                    })
                });

                if (!response.ok) {
                    throw new Error('Webhook failed');
                }
                console.log('Webhook sent successfully');
            } catch (error) {
                console.error('Error sending webhook:', error);
            }
        }

        // Set up signup handlers for all signup links
        if (signupLinks.length > 0) {
            console.log(`Setting up ${signupLinks.length} signup handlers`);
            signupLinks.forEach(link => {
                link.addEventListener('click', async function(e) {
                    console.log('Signup clicked');
                    const userData = {
                        firstName: this.dataset.userFirstName,
                        lastName: this.dataset.userLastName,
                        email: this.dataset.userEmail
                    };
                    const themeTitle = this.dataset.themeTitle;
                    await sendWorkingGroupWebhook('add', userData, themeTitle);
                });
            });
        }

        // Set up remove handlers for all remove links
        if (removeLinks.length > 0) {
            console.log(`Setting up ${removeLinks.length} remove handlers`);
            removeLinks.forEach(link => {
                link.addEventListener('click', async function(e) {
                    console.log('Remove clicked');
                    const userData = {
                        firstName: this.dataset.userFirstName,
                        lastName: this.dataset.userLastName,
                        email: this.dataset.userEmail
                    };
                    const themeTitle = this.dataset.themeTitle;
                    await sendWorkingGroupWebhook('remove', userData, themeTitle);
                });
            });
        }
    } catch (error) {
        console.error('Error setting up working group handlers:', error);
    }
});